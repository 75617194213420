/* eslint-disable compat/compat */
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';

const useStyles = makeStyles({
  label: {
    fontWeight: 'bold',
    marginRight: '5px',
  },
});

function TrueLinkSimpleLabel({ label, value, multiLine = false, style }) {
  const classes = useStyles();

  const handleCopy = useCallback(async () => {
    const clipboardItem = new window.ClipboardItem({
      'text/plain': value,
    });
    await window.navigator.clipboard.write([clipboardItem]);
  }, [value]);

  const copyIcon = (
    <TrueLinkIcon icon="copy" onClick={handleCopy} size="small" style={{ fontSize: '8px' }} />
  );

  return (
    <div style={style}>
      {multiLine ? (
        <>
          <span className={classes.label}>
            {label}: {value && copyIcon}
          </span>
          <br />
          <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>
        </>
      ) : (
        <>
          <span className={classes.label}>{label}:</span>
          <span>{value}</span>
          {value && copyIcon}
        </>
      )}
    </div>
  );
}

TrueLinkSimpleLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  style: PropTypes.object,
  multiLine: PropTypes.bool,
};

export default TrueLinkSimpleLabel;
